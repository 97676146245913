import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { getIsDebug } from '../helpers';

const Generic = props => {
	/** @type {MdRemarkNode} */
	const mdData = props.data.markdownRemark;
	const html = props.pageContext.modifiedHtml;
	if (typeof window !== 'undefined' && getIsDebug()) {
		window.debugData = typeof window.debugData === 'object' ? window.debugData : {};
		window.debugData.mdData = mdData;
		window.debugData.props = props;
	}

	const { title, slug, modifiedStampMs, createdStampMs } = mdData.fields;
	const isHomepage = slug === '/';

	/**
	 * Custom page description
	 */
	let customDescription = mdData.frontmatter.description;
	customDescription =
		typeof customDescription === 'string' && customDescription.length > 0 ? customDescription : null;

	const metaInfoBar = (
		<div className="metaInfoWrapper">
			{isHomepage ? (
				<>
					<div className="metaInfoBar">
						<div className="metaInfo">
							<div className="label">Website Markdown Last Updated:</div>
							<div className="value">
								{new Date(props.pageContext.mostRecentlyModifiedMarkdown.modifiedStampMs).toString()}
							</div>
						</div>
					</div>
					<div className="metaInfoBar">
						<div className="metaInfo">
							<div className="label">Homepage Markdown Last Updated:</div>
							<div className="value">{new Date(modifiedStampMs).toString()}</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="metaInfoBar">
						<div className="metaInfo">
							<div className="label">Markdown Source Last Updated:</div>
							<div className="value">{new Date(modifiedStampMs).toString()}</div>
						</div>
					</div>
					<div className="metaInfoBar">
						<div className="metaInfo">
							<div className="label">Markdown Source Created:</div>
							<div className="value">{new Date(createdStampMs).toString()}</div>
						</div>
					</div>
				</>
			)}
		</div>
	);

	return (
		<Layout pageTitle={title} slug={slug} footerChildren={metaInfoBar} headingInfo={mdData.tableOfContents}>
			<SEO title={title} description={customDescription} slug={slug}></SEO>
			<div dangerouslySetInnerHTML={{ __html: html }} />
		</Layout>
	);
};

export default Generic;

export const query = graphql`
	query ($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			tableOfContents
			frontmatter {
				title
				description
			}
			fields {
				slug
				title
				createdStampMs
				modifiedStampMs
			}
			parent {
				... on File {
					name
					modifiedTime
				}
			}
		}
	}
`;
